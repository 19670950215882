














import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class DataCard extends Vue {
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) subtitle!: number
  @Prop({ default: '' }) text!: string
}
