




















































import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

import DataCard from '@/partials/components/DataCard.vue'

@Component({
  components: {
    DataCard
  }
})
export default class Dashboard extends Vue {
  done = false
  created () {
    this.done = false
    axios.get('dashboard/all')
      .then(response => {
        const { servicesCount, procedures, servicesRegionalStratAvg } = camelCaseKeys(response.data.data, { deep: true })

        this.servicesDonut.data = servicesCount.data
        this.servicesDonut.options.donut.center.label = servicesCount.title

        this.servicesStratByRegionBar.data = servicesRegionalStratAvg.data
        this.servicesStratByRegionBar.options.title = servicesRegionalStratAvg.title

        this.proceduresBar.data = procedures.data
        this.proceduresBar.options.title = procedures.title
      })
      .finally(() => { this.done = true })
  }

  servicesDonut = {
    data: [],
    options: {
      resizable: true,
      donut: {
        center: {
          label: ''
        },
        alignment: 'center'
      },
      legend: {
        alignment: 'center'
      },
      height: '400px'
    }
  }

  servicesStratByRegionBar = {
    data: [],
    options: {
      title: ' ',
      alignment: 'center',
      axes: {
        left: {
          mapsTo: 'value'
        },
        bottom: {
          mapsTo: 'group',
          scaleType: 'labels'
        }
      },
      height: '400px'
    }
  }

  proceduresBar = {
    data: [],
    options: {
      title: ' ',
      alignment: 'center',
      axes: {
        left: {
          mapsTo: 'value'
        },
        bottom: {
          mapsTo: 'group',
          scaleType: 'labels'
        }
      },
      height: '400px'
    }
  }
}
